import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import actionSelector from "../../duck/selectors";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import RadioGroup from "../../../components/RadioGroup/RadioGroup";
import Select from "../../../components/Select/Select";
import { SelectOption } from "../../../types/SelectOption";
import { referenceTemperatureMethod } from "../../../types/ReferenceTemperatureMethod";
import gridStyles from "../../../css/GridForm.module.css";
import styles from "../CapitalEquipmentFilter/CapitalEquipmentFilter.module.css";
import {
  loadCapacityRules,
  roomTempRules,
  tempDiffRules,
} from "../CapitalEquipmentFilter/CapitalEquipmentValidation";
import { EvaporatorFilterModel } from "../../../types/EvaporatorFilterModel";
import { logPageEvent } from "../../../helpers/amplitude";
import { HeatLoadTotalCapacity } from "../../../heatLoad/types/HeatLoadCapacity";
import { StandardRoom } from "../../../types/HeatLoad";
import { calculateTD } from "../CapitalEquipmentFilter/CapitalEquipmentFilter";

interface EvaporatorSearchForm {
  capacity: string;
  refrigerant: string;
  mid_or_dew: string;
  roomTemp: string;
  tempDifference: string;
}

const EvaporatorSearchForm: FC<{
  scrollToEquipmentSelection: () => void;
}> = ({ scrollToEquipmentSelection }) => {
  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const heatLoadSummary: HeatLoadTotalCapacity = useSelector((state: any) => {
    return state.heatLoad.heatLoadCapacity;
  });

  const roomTemperature = roomDetails.temperature;
  const equipmentCapacity = Math.round(heatLoadSummary.fanLoad.total);
  const humidity = roomDetails.humidity;
  const tempDifference = calculateTD(roomTemperature, humidity);

  const defaultValues: EvaporatorSearchForm = {
    capacity: equipmentCapacity > 0 ? String(equipmentCapacity) : "3600",
    refrigerant: "R404A",
    mid_or_dew: "mid",
    roomTemp: equipmentCapacity > 0 ? String(roomTemperature) : "2",
    tempDifference: equipmentCapacity > 0 ? String(tempDifference) : "6",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<EvaporatorSearchForm>({
    defaultValues,
    mode: "onBlur",
  });
  let dispatch = useDispatch();

  const submit = (values: EvaporatorSearchForm) => {
    let submitValues: EvaporatorFilterModel = {
      refrigerant: values.refrigerant,
      capacity: +values.capacity,
      tempDifference: +values.tempDifference,
      roomTemp: +values.roomTemp,
      mid_or_dew: values.mid_or_dew,
    };

    dispatch(actionSelector.loadingBalancePerformance(true));
    dispatch(actionSelector.searchSingleEvaporator(submitValues));
    scrollToEquipmentSelection();
  };

  const refrigerantOptions: SelectOption[] = useSelector(
    (state: any) => state.product.equipmentRefrigerantOptions
  );

  useEffect(() => {
    setValue("refrigerant", "R404A");
  }, [refrigerantOptions, setValue]);

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.formContainer}>
      <div className={gridStyles.gridContainer}>
        <div>
          <div className="section-title">System details</div>

          <Input
            {...register("capacity", loadCapacityRules)}
            labelText="Required capacity"
            placeholderText="0 to 999000"
            innerLabel="W"
            hasErrors={errors.capacity !== undefined}
            errorMessage={errors.capacity?.message}
          />

          <Select
            {...register("refrigerant", { required: "Required" })}
            labelText="Refrigerant"
            options={refrigerantOptions}
            hasErrors={errors.refrigerant !== undefined}
            errorMessage={errors.refrigerant?.message}
          />
        </div>
        <div>
          <div className="section-title">Temperature details</div>

          <RadioGroup
            {...register("mid_or_dew", { required: "Required" })}
            labelText="Reference temp. method"
            values={referenceTemperatureMethod}
            hasErrors={errors.mid_or_dew !== undefined}
            errorMessage={errors.mid_or_dew?.message}
          />

          <Input
            {...register("roomTemp", roomTempRules)}
            labelText="Design room temp."
            placeholderText="-40° to +20°"
            innerLabel="°C"
            hasErrors={errors.roomTemp !== undefined}
            errorMessage={errors.roomTemp?.message}
          />

          <Input
            {...register("tempDifference", tempDiffRules)}
            labelText="Design temp. difference (TD)"
            placeholderText="3 to 12"
            innerLabel="K"
            hasErrors={errors.tempDifference !== undefined}
            errorMessage={errors.tempDifference?.message}
          />
        </div>
        <div>&nbsp;</div>
      </div>
      <div>
        <Button
          buttonStyle="primary"
          buttonType="submit"
          dataTestId="display_equipment"
          onClickHandler={(e) => logPageEvent("Display Evaporators only")}
        >
          Display equipment
        </Button>
      </div>
    </form>
  );
};

export default EvaporatorSearchForm;
